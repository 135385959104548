// src/components/Dashboard.js
import React, { useState, useEffect } from "react";
import { useQuery, useQueryClient, useMutation } from "@tanstack/react-query";
import api from "../api";
import {
  CircularProgress,
  Button,
  Typography,
  TextField,
  Grid,
  Snackbar,
  Alert,
} from "@mui/material";
import ServerCard from "./ServerCard";
import BigInt from "big-integer";

function Dashboard() {
  const queryClient = useQueryClient();

  const {
    data: user,
    isLoading: loadingUser,
    error: errorUser,
  } = useQuery({
    queryKey: ["user"],
    queryFn: () => api.get("/auth/user").then((res) => res.data),
  });

  const {
    data: userServers = [],
    isLoading: loadingServers,
    error: errorServers,
  } = useQuery({
    queryKey: ["userServers"],
    queryFn: () =>
      api.get("/servers/user").then((response) => response.data || []),
    enabled: !!user,
    initialData: [],
  });

  const [searchTerm, setSearchTerm] = useState("");
  const [message, setMessage] = useState("");
  const [availableTags, setAvailableTags] = useState([]);
  const [availableCategories, setAvailableCategories] = useState([]);

  // Récupérer les catégories disponibles depuis le backend
  useEffect(() => {
    api
      .get("/categories")
      .then((res) => setAvailableCategories(res.data))
      .catch((error) => {
        console.error("Erreur lors de la récupération des catégories :", error);
        setAvailableCategories([]);
      });
  }, []);

  // Récupérer les tags disponibles depuis le backend
  useEffect(() => {
    api
      .get("/tags")
      .then((res) => setAvailableTags(res.data))
      .catch((error) => {
        console.error("Erreur lors de la récupération des tags :", error);
        setAvailableTags([]);
      });
  }, []);

  // Définition de inviteURL
  const permissions = BigInt(user ? user.permissions : 0);
  const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
  const REDIRECT_URI = encodeURIComponent(
    process.env.REACT_APP_REDIRECT_URI_SERVERS_ADD
  );
  const RESPONSE_TYPE = "code";
  const SCOPE = encodeURIComponent("bot applications.commands");
  const inviteURL = `https://discord.com/oauth2/authorize?client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URI}&response_type=${RESPONSE_TYPE}&scope=${SCOPE}&permissions=${permissions}`;

  const filteredServers = Array.isArray(userServers)
    ? userServers.filter((server) =>
        server.name.toLowerCase().includes(searchTerm.toLowerCase())
      )
    : [];

  const mutation = useMutation(
    ({ id, categories, tags, description, reviews_count }) =>
      api.put(`/servers/${id}`, {
        categories,
        tags,
        description,
        reviews_count,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("userServers");
        setMessage("Serveur mis à jour avec succès");
      },
      onError: (error) => {
        console.error("Erreur lors de la mise à jour du serveur:", error);
        setMessage("Erreur lors de la mise à jour du serveur");
      },
    }
  );

  const handleUpdate = (serverId, updatedData) => {
    mutation.mutate({ id: serverId, ...updatedData });
  };

  if (loadingUser || loadingServers) {
    return <CircularProgress />;
  }

  if (errorUser || errorServers) {
    console.error("Erreur lors du chargement des données :", {
      errorUser,
      errorServers,
    });
    return (
      <Alert severity="error">
        Une erreur s'est produite. Veuillez réessayer plus tard.
      </Alert>
    );
  }

  if (!user) {
    return (
      <Typography>
        Vous devez être connecté pour accéder à cette page.
      </Typography>
    );
  }

  const handleDelete = async (serverId) => {
    if (window.confirm("Êtes-vous sûr de vouloir supprimer ce serveur ?")) {
      try {
        await api.delete(`/servers/${serverId}`);
        queryClient.invalidateQueries("userServers");
        setMessage("Serveur supprimé avec succès");
      } catch (error) {
        console.error("Erreur lors de la suppression du serveur :", error);
        setMessage("Erreur lors de la suppression du serveur");
      }
    }
  };

  return (
    <div>
      <Typography variant="h4" gutterBottom>
        Bienvenue, {user.username}#{user.discriminator}
      </Typography>
      <Button
        aria-label="Ajouter un serveur"
        variant="contained"
        href={inviteURL}
        sx={{ mb: 2 }}
      >
        Ajouter un Serveur
      </Button>
      <Typography variant="h5">Vos Serveurs</Typography>
      <TextField
        variant="outlined"
        placeholder="Rechercher un serveur..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        sx={{ mb: 2 }}
      />
      <Grid container spacing={2}>
        {filteredServers.map((server) => (
          <Grid item xs={12} sm={6} md={4} key={server.id}>
            <ServerCard
              server={server}
              handleDelete={handleDelete}
              handleUpdate={handleUpdate}
              availableCategories={availableCategories}
              availableTags={availableTags}
              isDashboard={true}
            />
          </Grid>
        ))}
      </Grid>
      <Snackbar
        open={!!message}
        autoHideDuration={6000}
        onClose={() => setMessage("")}
      >
        <Alert
          onClose={() => setMessage("")}
          severity={message.startsWith("Erreur") ? "error" : "success"}
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default Dashboard;
