import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  Typography,
  Box,
  CardActions,
  Button,
  Stack,
  Tooltip,
  TextField,
  Autocomplete,
  useTheme,
  Grow,
  CardMedia,
} from "@mui/material";
import { Link } from "react-router-dom";
import {
  People,
  ChatBubble,
  RecordVoiceOver,
  TrendingUp,
  TrendingDown,
  Forum,
} from "@mui/icons-material";
import CustomLinearProgress from "./CustomLinearProgress";

function ServerCard({
  server,
  handleDelete,
  handleUpdate,
  availableTags,
  availableCategories,
  isDashboard,
  isAdmin,
}) {
  // États locaux
  const [categories, setCategories] = useState(server.categories || []);
  const [tags, setTags] = useState(server.tags || []);
  const [description, setDescription] = useState(server.description || "");
  const theme = useTheme();

  // Limites
  const maxCategories = 2;
  const maxTags = 3;
  const maxDescription = 150;
  const maxCharactersForTags = 10;

  // Construction de l'URL complète de l'image
  const imageUrl = server.imageurl
    ? `https://cdn.discordapp.com/icons/${server.id}/${server.imageurl}.png?size=300`
    : null;

  const onHandleUpdate = () => {
    handleUpdate(server.id, { categories, tags, description });
  };

  return (
    <Grow in={true}>
      <Card
        sx={{
          maxWidth: 345,
          margin: "auto",
          display: "flex",
          flexDirection: "column",
          height: "100%",
          borderRadius: theme.shape.borderRadius * 2,
          boxShadow: theme.shadows[6],
          transition: "transform 0.3s",
          "&:hover": {
            transform: "scale(1.05)",
            boxShadow: theme.shadows[10],
          },
        }}
      >
        {imageUrl ? (
          <CardMedia
            component="img"
            height="140"
            image={imageUrl}
            alt={server.name}
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = "/images/default.png";
            }}
          />
        ) : (
          <Box
            sx={{
              backgroundColor: "grey.500",
              height: 140,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography variant="h5" color="white">
              {server.name[0]}
            </Typography>
          </Box>
        )}
        <CardContent
          sx={{
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between", // Assure que le contenu est bien réparti
            minHeight: 200, // Hauteur minimale pour le contenu
          }}
        >
          <Typography variant="h6" gutterBottom>
            {server.name}
          </Typography>

          {/* Affichage de la description du serveur */}
          {!isDashboard && (
            <Typography
              variant="body2"
              color="textSecondary"
              sx={{
                mb: 2,
                overflow: "hidden",
                display: "-webkit-box",
                WebkitLineClamp: 3, // Limite à 3 lignes
                WebkitBoxOrient: "vertical",
              }}
            >
              {server.description || "Aucune description disponible."}
            </Typography>
          )}

          {/* Partie pour le Dashboard, permettant d'éditer la description */}
          {isDashboard && (
            <>
              {/* Gestion des catégories */}
              <Autocomplete
                multiple
                options={availableCategories}
                value={categories}
                onChange={(event, newValue) => {
                  if (newValue.length <= maxCategories) {
                    setCategories(newValue);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label={`Catégories (max ${maxCategories})`}
                    placeholder="Sélectionner des catégories"
                  />
                )}
                sx={{ mb: 2 }}
              />
              {/* Gestion des tags */}
              <Autocomplete
                multiple
                freeSolo
                options={availableTags}
                value={tags}
                onChange={(event, newValue) => {
                  if (
                    newValue.length <= maxTags &&
                    newValue.every((tag) => tag.length <= maxCharactersForTags)
                  ) {
                    setTags(newValue);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label={`Tags (max ${maxTags}, 10 caractères max par tag)`}
                    placeholder="Ajouter des tags"
                  />
                )}
                sx={{ mb: 2 }}
              />
              {/* Gestion de la description */}
              <TextField
                label="Description"
                multiline
                rows={3}
                value={description}
                onChange={(e) => {
                  if (e.target.value.length <= maxDescription) {
                    setDescription(e.target.value);
                  }
                }}
                variant="outlined"
                sx={{ mb: 2 }}
                helperText={`${description.length}/${maxDescription} caractères`}
              />
            </>
          )}

          {/* Les métriques */}
          <Stack
            direction="row"
            spacing={2}
            sx={{ mt: 2, flexWrap: "wrap", justifyContent: "center" }}
          >
            {/* Membres */}
            <Stack direction="row" alignItems="center" spacing={0.5}>
              <Tooltip title="Membres">
                <People sx={{ color: "primary.main" }} />
              </Tooltip>
              <Typography variant="body2">{server.member_count}</Typography>
            </Stack>
            {/* Actifs (24h) */}
            <Stack direction="row" alignItems="center" spacing={0.5}>
              <Tooltip title="Actifs (24h)">
                <People sx={{ color: "success.main" }} />
              </Tooltip>
              <Typography variant="body2">
                {server.active_chat_members_count}
              </Typography>
            </Stack>
            {/* En vocal */}
            <Stack direction="row" alignItems="center" spacing={0.5}>
              <Tooltip title="En vocal">
                <RecordVoiceOver sx={{ color: "info.main" }} />
              </Tooltip>
              <Typography variant="body2">
                {server.voice_member_count || 0}
              </Typography>
            </Stack>
            {/* Flux mensuel */}
            <Stack direction="row" alignItems="center" spacing={0.5}>
              <Tooltip title="Flux mensuel">
                {server.net_change >= 0 ? (
                  <TrendingUp sx={{ color: "success.main" }} />
                ) : (
                  <TrendingDown sx={{ color: "error.main" }} />
                )}
              </Tooltip>
              <Typography variant="body2">{server.net_change}</Typography>
            </Stack>
            {/* Commentaires */}
            <Stack direction="row" alignItems="center" spacing={0.5}>
              <Tooltip title="Commentaires">
                <Forum sx={{ color: "secondary.main" }} />
              </Tooltip>
              <Typography variant="body2">
                {server.reviews_count || 0}
              </Typography>
            </Stack>
          </Stack>
          {/* Barre de progression indiquant la note moyenne */}
          <CustomLinearProgress
            variant="determinate"
            value={server.average_rating}
            sx={{ mt: 1 }}
          />
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            spacing={1}
          >
            <Typography variant="body2">{server.average_rating}%</Typography>
          </Stack>
        </CardContent>
        <CardActions sx={{ justifyContent: "center" }}>
          {server.invite_link ? (
            <Button
              size="small"
              color="primary"
              href={server.invite_link}
              target="_blank"
              rel="noopener noreferrer"
              variant="contained"
              disabled={!server.invite_link}
            >
              Rejoindre
            </Button>
          ) : (
            <Typography variant="body2" color="textSecondary">
              Invitation non disponible
            </Typography>
          )}
          <Button
            size="small"
            component={Link}
            to={`/server/${server.id}`}
            color="secondary"
            variant="outlined"
          >
            Détails
          </Button>
          {/* Boutons conditionnels pour le Dashboard */}
          {isDashboard && (
            <>
              {/* Bouton pour sauvegarder les modifications */}
              <Button
                size="small"
                color="success"
                variant="contained"
                onClick={onHandleUpdate}
              >
                Sauvegarder
              </Button>
              {/* Bouton pour supprimer le serveur */}
              <Button
                size="small"
                color="error"
                variant="contained"
                onClick={() => handleDelete(server.id)}
              >
                Supprimer
              </Button>
            </>
          )}
          {/* Bouton de suppression pour l'administrateur */}
          {isAdmin && (
            <Button
              size="small"
              color="error"
              variant="contained"
              onClick={() => handleDelete(server.id)}
            >
              Supprimer
            </Button>
          )}
        </CardActions>
      </Card>
    </Grow>
  );
}

export default ServerCard;
