import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  Navigate,
} from "react-router-dom";
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  useMediaQuery,
  ThemeProvider,
  createTheme,
  useTheme,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import api from "./api";
import Home from "./components/Home";
import Dashboard from "./components/Dashboard";
import NotFound from "./components/NotFound";
import ServerDetails from "./components/ServerDetails";
import AdminPanel from "./components/AdminPanel";

function App() {
  const [user, setUser] = useState(null);
  const [loadingUser, setLoadingUser] = useState(true);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const principalTheme = createTheme({
    palette: {
      mode: "dark",
      primary: {
        main: "#90caf9",
      },
      secondary: {
        main: "#f48fb1",
      },
      background: {
        default: "#121212",
        paper: "#1e1e1e",
      },
      text: {
        primary: "#ffffff",
        secondary: "#b0b0b0",
      },
    },
    typography: {
      fontFamily: "Roboto, Arial",
    },
  });

  

  useEffect(() => {
    api
      .get("/auth/user")
      .then((response) => {
        setUser(response.data);
        setLoadingUser(false);
      })
      .catch((error) => {
        console.error(
          "Erreur lors de la récupération de l'utilisateur :",
          error
        );
        setLoadingUser(false);
      });
  }, []);

  const handleLogout = () => {
    api
      .get("/auth/logout")
      .then(() => {
        setUser(null);
      })
      .catch((error) => {
        console.error("Erreur lors de la déconnexion:", error);
      });
  };

  const toggleDrawer = (open) => () => {
    setDrawerOpen(open);
  };

  const menuItems = (
    <List>
      <ListItem button component={Link} to="/" onClick={toggleDrawer(false)}>
        <ListItemText primary="Accueil" />
      </ListItem>
      {user && (
        <>
          <ListItem button component={Link} to="/dashboard" onClick={toggleDrawer(false)}>
            <ListItemText primary="Tableau de Bord" />
          </ListItem>
          {user.isAdmin && (
            <ListItem button component={Link} to="/admin" onClick={toggleDrawer(false)}>
              <ListItemText primary="Administration" />
            </ListItem>
          )}
          <ListItem button onClick={() => { handleLogout(); toggleDrawer(false) }}>
            <ListItemText primary="Déconnexion" />
          </ListItem>
        </>
      )}
      {!user && (
        <ListItem button onClick={() => {
          window.location.href = `${process.env.REACT_APP_API_URL}/auth/discord`;
          toggleDrawer(false);
        }}>
          <ListItemText primary="Connexion avec Discord" />
        </ListItem>
      )}
    </List>
  );

  if (loadingUser) {
    return <Typography>Chargement de l'utilisateur...</Typography>;
  }

  return (
    <ThemeProvider theme={principalTheme}>
      <Router>
        <div className="App">
          <AppBar position="static">
            <Toolbar>
              {isMobile && (
                <IconButton
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  onClick={toggleDrawer(true)}
                >
                  <MenuIcon />
                </IconButton>
              )}
              <Typography variant="h6" sx={{ flexGrow: 1 }}>
                Etheris
              </Typography>
              {!isMobile && (
                <>
                  <Button color="inherit" component={Link} to="/">
                    Accueil
                  </Button>
                  {user && (
                    <>
                      <Button color="inherit" component={Link} to="/dashboard">
                        Tableau de Bord
                      </Button>
                      {user.isAdmin && (
                        <Button color="inherit" component={Link} to="/admin">
                          Administration
                        </Button>
                      )}
                      <Button color="inherit" onClick={handleLogout}>
                        Déconnexion
                      </Button>
                    </>
                  )}
                  {!user && (
                    <Button
                      color="inherit"
                      href={`${process.env.REACT_APP_API_URL}/auth/discord`}
                    >
                      Connexion avec Discord
                    </Button>
                  )}
                </>
              )}
            </Toolbar>
          </AppBar>
          <Drawer
            anchor="left"
            open={drawerOpen}
            onClose={toggleDrawer(false)}
          >
            {menuItems}
          </Drawer>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/server/:id" element={<ServerDetails />} />
            <Route
              path="/admin"
              element={
                user && user.isAdmin ? <AdminPanel /> : <Navigate to="/" />
              }
            />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </div>
      </Router>
    </ThemeProvider>
  );
}

export default App;