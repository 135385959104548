import React, { useState } from "react";
import { useParams } from "react-router-dom";
import api from "../api";
import {
  Typography,
  CircularProgress,
  Card,
  CardContent,
  CardMedia,
  Tabs,
  Tab,
  Box,
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  Tooltip,
  Chip,
  Button,
  Rating,
  Grid,
} from "@mui/material";
import ReviewForm from "./ReviewForm";
import CustomLinearProgress from "./CustomLinearProgress";
import {
  People,
  ChatBubble,
  RecordVoiceOver,
  TrendingUp,
  TrendingDown,
  Forum,
  Category,
  Tag,
} from "@mui/icons-material";
import { useQuery } from "@tanstack/react-query";

function ServerDetails() {
  const { id } = useParams();
  const [tabValue, setTabValue] = useState(0);

  const {
    data: server,
    isLoading,
    error,
    refetch,
  } = useQuery(["server", id], () =>
    api.get(`/servers/${id}`).then((res) => res.data)
  );

  const { refetch: refetchServers } = useQuery(["servers"], () =>
    api.get("/servers").then((res) => res.data.servers)
  );

  const handleReviewSubmitted = () => {
    refetch(); // Recharge les détails du serveur
    refetchServers(); // Recharge la liste des serveurs
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  if (isLoading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Typography color="error" align="center" sx={{ mt: 4 }}>
        Erreur lors de la récupération du serveur.
      </Typography>
    );
  }

  const imageUrl = server.imageurl
    ? `https://cdn.discordapp.com/icons/${server.id}/${server.imageurl}.png?size=300`
    : null;

  return (
    <Card sx={{ maxWidth: 900, margin: "auto", mt: 4, boxShadow: 3 }}>
      <CardMedia
        component="img"
        image={imageUrl || "/images/default-server-image.png"}
        alt={server.name}
        sx={{ height: { xs: 200, sm: 300 }, objectFit: "cover" }}
      />
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={8}>
            <Typography variant="h4" gutterBottom>
              {server.name}
            </Typography>
            <Button
              variant="contained"
              color="primary"
              href={server.invite_link}
              target="_blank"
              sx={{ mb: 2 }}
            >
              Rejoindre le serveur
            </Button>

            {/* Affichage des catégories */}
            {server.categories && server.categories.length > 0 && (
              <Box sx={{ mb: 2 }}>
                <Typography variant="h6" gutterBottom>
                  <Category sx={{ verticalAlign: "middle", mr: 1 }} />
                  Catégories
                </Typography>
                <Stack direction="row" spacing={1} flexWrap="wrap">
                  {server.categories.map((category) => (
                    <Chip
                      key={category}
                      label={category}
                      color="primary"
                      variant="outlined"
                      sx={{ mb: 1 }}
                    />
                  ))}
                </Stack>
              </Box>
            )}

            {/* Affichage des tags */}
            {server.tags && server.tags.length > 0 && (
              <Box sx={{ mb: 2 }}>
                <Typography variant="h6" gutterBottom>
                  <Tag sx={{ verticalAlign: "middle", mr: 1 }} />
                  Tags
                </Typography>
                <Stack direction="row" spacing={1} flexWrap="wrap">
                  {server.tags.map((tag) => (
                    <Chip
                      key={tag}
                      label={`#${tag}`}
                      variant="outlined"
                      color="secondary"
                      sx={{ mb: 1 }}
                    />
                  ))}
                </Stack>
              </Box>
            )}

            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              indicatorColor="primary"
              textColor="primary"
              sx={{ mb: 2 }}
              variant="fullWidth"
            >
              <Tab label="Informations" />
              <Tab label="Commentaires" />
              <Tab label="Membres en ligne" />
            </Tabs>

            <Box sx={{ mt: 2 }}>
              {tabValue === 0 && (
                <>
                  <Typography variant="body1" paragraph>
                    {server.description || "Aucune description disponible."}
                  </Typography>
                  <Stack
                    direction={{ xs: "column", sm: "row" }}
                    spacing={2}
                    sx={{ mt: 2 }}
                    justifyContent="flex-start"
                  >
                    {/* Membres */}
                    <Stack direction="row" alignItems="center" spacing={0.5}>
                      <Tooltip title="Membres">
                        <People color="primary" />
                      </Tooltip>
                      <Typography variant="body2">
                        {server.member_count}
                      </Typography>
                    </Stack>

                    {/* Actifs (24h) */}
                    <Stack direction="row" alignItems="center" spacing={0.5}>
                      <Tooltip title="Actifs (24h)">
                        <ChatBubble color="success" />
                      </Tooltip>
                      <Typography variant="body2">
                        {server.active_chat_members_count}
                      </Typography>
                    </Stack>

                    {/* En vocal */}
                    <Stack direction="row" alignItems="center" spacing={0.5}>
                      <Tooltip title="En vocal">
                        <RecordVoiceOver color="info" />
                      </Tooltip>
                      <Typography variant="body2">
                        {server.voice_member_count || 0}
                      </Typography>
                    </Stack>

                    {/* Flux mensuel */}
                    <Stack direction="row" alignItems="center" spacing={0.5}>
                      <Tooltip title="Flux mensuel">
                        {server.net_change >= 0 ? (
                          <TrendingUp color="success" />
                        ) : (
                          <TrendingDown color="error" />
                        )}
                      </Tooltip>
                      <Typography variant="body2">
                        {server.net_change}
                      </Typography>
                    </Stack>

                    {/* Commentaires */}
                    <Stack direction="row" alignItems="center" spacing={0.5}>
                      <Tooltip title="Commentaires">
                        <Forum color="secondary" />
                      </Tooltip>
                      <Typography variant="body2">
                        {server.reviews.length}
                      </Typography>
                    </Stack>
                  </Stack>

                  <Box sx={{ mt: 2 }}>
                    <CustomLinearProgress
                      value={server.average_rating}
                      sx={{ height: 10, borderRadius: 5 }}
                    />
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      align="right"
                    >
                      {server.average_rating}%
                    </Typography>
                  </Box>
                </>
              )}
              {tabValue === 1 && (
                <>
                  <ReviewForm
                    serverId={id}
                    onReviewSubmitted={handleReviewSubmitted}
                  />
                  {server.reviews && server.reviews.length > 0 ? (
                    <List>
                      {server.reviews.map((review) => (
                        <ListItem key={review.id} alignItems="flex-start">
                          <ListItemAvatar>
                            <Avatar
                              alt={review.username}
                              src={review.avatarUrl}
                            />
                          </ListItemAvatar>
                          <ListItemText
                            primary={
                              <Box
                                sx={{ display: "flex", alignItems: "center" }}
                              >
                                <Typography variant="body1">
                                  {review.username}
                                </Typography>
                                <Rating
                                  value={review.rating / 20} // Convertir de 0-100 à 0-5
                                  precision={0.5}
                                  readOnly
                                  sx={{ ml: 2 }}
                                />
                              </Box>
                            }
                            secondary={review.comment}
                          />
                        </ListItem>
                      ))}
                    </List>
                  ) : (
                    <Typography>Aucun commentaire pour le moment.</Typography>
                  )}
                </>
              )}
              {tabValue === 2 && (
                <>
                  {server.onlineMembers && server.onlineMembers.length > 0 ? (
                    <List>
                      {server.onlineMembers.map((member) => (
                        <ListItem key={member.id}>
                          <ListItemAvatar>
                            <Avatar
                              alt={member.username}
                              src={member.avatarUrl}
                            />
                          </ListItemAvatar>
                          <ListItemText primary={member.username} />
                        </ListItem>
                      ))}
                    </List>
                  ) : (
                    <Typography>Aucun membre en ligne.</Typography>
                  )}
                </>
              )}
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

export default ServerDetails;
