import React from "react";
import { LinearProgress, styled } from "@mui/material";

const StyledLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${LinearProgress.name}-colorPrimary`]: {
    backgroundColor: theme.palette.grey[300],
  },
  [`& .${LinearProgress.name}-bar`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.primary.main,
  },
}));

function CustomLinearProgress(props) {
  return <StyledLinearProgress variant="determinate" {...props} />;
}

export default CustomLinearProgress;
