import React, { useState } from "react";
import { TextField, Button, Typography, Box, Slider } from "@mui/material";
import api from "../api";

function ReviewForm({ serverId, onReviewSubmitted }) {
  const [rating, setRating] = useState(50);
  const [comment, setComment] = useState("");
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setSuccess(null);

    try {
      const response = await api.post(`/servers/${serverId}/reviews`, { rating, comment });
      setSuccess(response.data.message);
      setRating(50);
      setComment("");
      onReviewSubmitted(); // Appeler la fonction de rappel
    } catch (err) {
      setError(err.response?.data?.message || "Erreur lors de l'ajout de l'avis.");
    }
  };

  const handleRatingChange = (event, newValue) => {
    setRating(newValue);
  };

  return (
    <Box sx={{ mt: 4 }}>
      <Typography variant="h6">Ajouter un Avis</Typography>
      <form onSubmit={handleSubmit}>
        <Box sx={{ display: "flex", alignItems: "center", mt: 2 }}>
          <Typography variant="body1" sx={{ mr: 2 }}>
            Note :
          </Typography>
          <Slider
            value={rating}
            onChange={handleRatingChange}
            step={1}
            marks
            min={0}
            max={100}
            valueLabelDisplay="auto"
            sx={{
              color: "#ffea00",
              width: 200,
              "& .MuiSlider-thumb": {
                "&:hover, &.Mui-active, &.Mui-focusVisible": {
                  boxShadow: "none",
                },
              },
              "& .MuiSlider-rail": {
                opacity: 0.5,
              },
            }}
          />
          <Typography variant="body2" sx={{ ml: 2 }}>
            {rating}/100
          </Typography>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            sx={{ ml: "auto" }}
          >
            Soumettre
          </Button>
        </Box>
        <TextField
          label="Commentaire"
          multiline
          rows={4}
          variant="outlined"
          fullWidth
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          sx={{ mt: 2 }}
        />
        {error && (
          <Typography color="error" sx={{ mt: 1 }}>
            {error}
          </Typography>
        )}
        {success && (
          <Typography color="primary" sx={{ mt: 1 }}>
            {success}
          </Typography>
        )}
      </form>
    </Box>
  );
}

export default ReviewForm;
