import React, { useState, useEffect, useMemo } from "react";
import { useQuery } from "@tanstack/react-query";
import api from "../api";
import {
  Grid,
  Typography,
  TextField,
  Box,
  Chip,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import ServerCard from "./ServerCard";

function Home() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [sortOption, setSortOption] = useState("average_rating");
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [filteredServers, setFilteredServers] = useState([]);

  const handleSortChange = (event) => {
    setSortOption(event.target.value);
  };

  const { data, isLoading, error } = useQuery({
    queryKey: ["allServers", sortOption],
    queryFn: () => api.get(`/servers?sort=${sortOption}`).then((res) => res.data),
  });

  const servers = useMemo(() => (data ? data.servers : []), [data]);

  const sortedServers = useMemo(() => {
    const sorted = [...filteredServers];
    sorted.sort((a, b) => {
      switch (sortOption) {
        case "average_rating":
          return b.average_rating - a.average_rating;
        case "voice_member_count":
          return b.voice_member_count - a.voice_member_count;
        case "active_chat_members_count":
          return b.active_chat_members_count - a.active_chat_members_count;
        case "member_count":
          return b.member_count - a.member_count;
        default:
          return 0;
      }
    });
    return sorted;
  }, [filteredServers, sortOption]);

  useEffect(() => {
    if (Array.isArray(servers)) {
      let filtered = servers;

      if (searchTerm) {
        filtered = filtered.filter((server) =>
          server.name.toLowerCase().includes(searchTerm.toLowerCase())
        );
      }

      if (selectedCategory) {
        filtered = filtered.filter(
          (server) =>
            Array.isArray(server.categories) &&
            server.categories.includes(selectedCategory)
        );
      }

      setFilteredServers(filtered);
    } else {
      setFilteredServers([]);
    }
  }, [servers, searchTerm, selectedCategory]);

  if (isLoading) return <Typography>Chargement...</Typography>;
  if (error) return <Typography>Erreur de chargement.</Typography>;

  // Récupérer toutes les catégories disponibles
  const allCategories = Array.from(
    new Set(servers.flatMap((server) => server.categories || []))
  ).filter(Boolean);

  return (
    <Box sx={{ p: 3 }}>
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12} textAlign="center">
          <Typography variant="h3" gutterBottom>
            Découvrez les meilleurs serveurs Discord
          </Typography>
        </Grid>

        <Grid item xs={12} md={6} textAlign="center">
          <FormControl variant="outlined" sx={{ minWidth: 200, mb: isMobile ? 2 : 0 }}>
            <InputLabel id="sort-label">Trier par</InputLabel>
            <Select
              labelId="sort-label"
              value={sortOption}
              onChange={handleSortChange}
              label="Trier par"
            >
              <MenuItem value="average_rating">Avis</MenuItem>
              <MenuItem value="voice_member_count">Personnes en vocal</MenuItem>
              <MenuItem value="active_chat_members_count">Actifs (24h)</MenuItem>
              <MenuItem value="member_count">Total de membres</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} md={6} textAlign="center">
          <TextField
            variant="outlined"
            placeholder="Rechercher un serveur..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            sx={{ mb: 2 }}
          />
        </Grid>

        <Grid item xs={12} textAlign="center">
          <Typography variant="h6" gutterBottom>
            Filtrer par Catégorie
          </Typography>
          {allCategories.map((category) => (
            <Chip
              key={category}
              label={category}
              onClick={() => setSelectedCategory(category)}
              color={selectedCategory === category ? "primary" : "default"}
              sx={{ m: 0.5 }}
            />
          ))}
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={2} justifyContent="center">
            {sortedServers.map((server) => (
              <Grid item xs={12} sm={6} md={4} key={server.id}>
                <ServerCard server={server} />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Home;
