import React from "react";
import { useQuery } from "@tanstack/react-query";
import api from "../api";
import { Typography, CircularProgress, Grid } from "@mui/material";
import ServerCard from "./ServerCard";

function AdminPanel() {
  const { data, isLoading, error, refetch } = useQuery(["allServers"], () =>
    api.get("/admin/servers").then((res) => res.data.servers)
  );

  const handleDelete = async (serverId) => {
    if (window.confirm("Êtes-vous sûr de vouloir supprimer ce serveur ?")) {
      try {
        await api.delete(`/admin/servers/${serverId}`);
        alert("Serveur supprimé avec succès");
        refetch();
      } catch (error) {
        console.error("Erreur lors de la suppression du serveur :", error);
        alert("Erreur lors de la suppression du serveur");
      }
    }
  };

  if (isLoading) return <CircularProgress />;
  if (error)
    return <Typography>Erreur lors du chargement des serveurs.</Typography>;

  return (
    <div>
      <Typography variant="h4" gutterBottom>
        Panneau d'Administration
      </Typography>
      <Grid container spacing={2}>
        {data.map((server) => (
          <Grid item xs={12} sm={6} md={4} key={server.id}>
            <ServerCard
              server={server}
              handleDelete={handleDelete}
              isAdmin={true}
            />
          </Grid>
        ))}
      </Grid>
    </div>
  );
}

export default AdminPanel;
